<template>
  <v-row class="ma-0 onboarding-details">
    <v-col cols="12" class="pa-0">
      <v-list
        subheader
      >
        <v-subheader>Contact</v-subheader>
        <v-divider class="pb-2"></v-divider>
        <v-list-item
          v-for="(detail, i) in basicDetails"
          :key="`basic${i}`"
          :class="{'hidden-screen-only': (!detail.value && mode === 'normal')}"
          class="d-flex align-middle"
        >
          <v-list-item-avatar :class="mode">
            <v-img :src="detail.avatar" contain></v-img>
          </v-list-item-avatar>
          <v-list-item-content class="social-item">
          <BaseInput
            v-if="detail.name === 'number' && mode === 'edit'"
            :label="detail.placeholder"
            :key="`input${i}`"
            v-mask="'###-###-####'"
            type="tel"
            outlined
            dense
            :mode="mode"
            :disabled="detail.disable"
            :name="detail.name"
            v-model.trim="profile[detail.name]"
            :status="errors(detail.name).length ? 'error': 'normal'"
            :error-messages="errors(detail.name)"
            @input="$v.profile[detail.name].$touch()"
            @blur="$v.profile[detail.name].$touch()"
          >
            <div class='my-1'> {{ detail.value }} </div>
          </BaseInput>
          <BaseInput
           v-else
            :label="detail.placeholder"
            outlined
            dense
            :mode="mode"
            :disabled="detail.disable"
            :name="detail.name"
            v-model.trim="profile[detail.name]"
            :status="errors(detail.name).length ? 'error': 'normal'"
            :error-messages="errors(detail.name)"
            @change="errors(detail.name)"
            @input="$v.profile[detail.name].$touch()"
            @blur="$v.profile[detail.name].$touch()"
          >
            <div class='mb-2 about-contact'>
              <span> {{ detail.value }} </span>
            </div>
          </BaseInput>
          </v-list-item-content>
        </v-list-item>

        <v-subheader :class="{'ma-0': mode === 'edit'}">
          Segment
        </v-subheader>
        <v-divider class="pb-2"></v-divider>
        <v-list-item
          :class="{'hidden-screen-only': (!profile.businessTypeIds && mode === 'normal')}"
        >
          <AutoCompleteField
            :mode="mode"
            multiple
            outlined
            dense
            :items="businessTypes"
            item-text="shortName"
            item-value="id"
            placeholder="Segments"
            name="businessTypeIds"
            class="notranslate"
            v-model="profile.businessTypeIds"
            :status="errors('businessTypeIds').length ? 'error': 'normal'"
            :error-messages="errors('businessTypeIds')"
            @input="$v.profile.businessTypeIds.$touch()"
            @blur="$v.profile.businessTypeIds.$touch()"
          >
            <v-chip
              color="#e5e7fa"
              label
              v-for="(id, k) in profile.businessTypeIds"
              :key="`segment${k}`"
            >
              {{ getText(id, businessTypes, 'shortName') }}
            </v-chip>
          </AutoCompleteField>
        </v-list-item>
        <v-subheader  :class="{'ma-0': mode === 'edit'}">
          Social
        </v-subheader>
        <v-divider class="pb-2"></v-divider>
        <v-list-item
          v-for="(link, j) in socialLinks" :key="`link${j}`"
          class="icon-position d-flex align-middle"
        >
          <v-list-item-avatar :class="mode">
            <v-img :src="link.avatar" contain></v-img>
          </v-list-item-avatar>
          <v-list-item-content class="social-item">
            <BaseInput
              :label="link.placeholder"
              outlined
              dense
              :mode="mode"
              :disabled="link.disable"
              :name="link.name"
              v-model.trim="profile[link.name]"
              :status="errors(link.name).length ? 'error': 'normal'"
              :error-messages="errors(link.name)"
              @input="$v.profile[link.name].$touch()"
              @blur="$v.profile[link.name].$touch()"
            >
              <a class="anchorlink a-link mb-2" :href="generateLink(link.value)" target="_blank">
                {{link.value}}
              </a>
            </BaseInput>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable global-require */

import _ from 'lodash';
import { mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import {
  email, required, minLength, maxLength, numeric, requiredIf,
}
from 'vuelidate/lib/validators';
import { url } from '@/helpers';
import BaseInput from '@/components/common/BaseInput';
import AutoCompleteField from '@/components/common/AutoCompleteField';

export default {
  name: 'CreativeOnboarding',
  components: {
    BaseInput,
    AutoCompleteField,
  },
  directives: {
    mask,
  },
  props: {
    mode: {
      type: String,
      default: 'normal',
    },
    role: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('profile', { businessTypes: 'executiveBusinessTypes' }),
    ...mapGetters('profile', { profile: 'producerProfile' }),
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.profile[field].$dirty) return errors;
        const calendlyValidation = ((!this.profile.isTraine && this.role === 'producer') || (this.profile.isDefaultContact && this.role === 'executive'));

        switch (field) {
          case 'number':
            if (!this.$v.profile.number.required) { errors.push('Please provide your Phone Number'); }
            if (!this.$v.profile.number.minLength) { errors.push('Please provide valid Phone Number'); }
            break;
          case 'email':
            if (!this.$v.profile.email.required) { errors.push('Please provide your Email'); }
            if (!this.$v.profile.email.email) { errors.push('Please provide valid Email'); }
            break;
          case 'calendly':
            if (!this.$v.profile.calendly.required && calendlyValidation) { errors.push('Please provide your calendly link'); }
            // eslint-disable-next-line
            if ( this.$v.profile.calendly.$model && this.$v.profile.calendly.$model.indexOf('calendly.com')<0) { errors.push('Please provide valid calendly url'); }
            break;
          case 'website':
            if (!this.$v.profile.website.required) { errors.push('Please provide your website'); }
            if (!this.$v.profile.website.url) { errors.push('Please provide valid url'); }
            break;

          case 'instagramUrl':
          case 'facebookUrl':
            if (!this.$v.profile[field].url) { errors.push('Please provide valid url'); }
            break;
          case 'zipcode':
            if (!this.$v.profile.zipcode.minLength) {
              errors.push('Zipcode is required');
            }
            if (!this.$v.profile.zipcode.validZipcode) {
              errors.push('Invalid Zipcode');
            }
            // if (!this.$v.profile.zipcode.required)
            //  { errors.push('Please provide your Zip Code'); }
            // if (!this.$v.profile.zipcode.numeric)
            //  { errors.push('Zip Code must be numeric'); }
            // if (!this.$v.profile.zipcode.minLength
            //  || !this.$v.profile.zipcode.maxLength)
            //   { errors.push('Zip Code length should be 5 digits'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    basicDetails() {
      return ([{
        name: 'number',
        placeholder: 'Phone Number',
        value: this.profile.number,
        avatar: require('@/assets/svg/theme/phone-outline.svg'),
      }, {
        name: 'email',
        placeholder: 'Email',
        value: this.profile.email,
        avatar: require('@/assets/svg/theme/email-outline.svg'),
      }, {
        name: 'website',
        placeholder: 'Website',
        value: this.profile.website,
        avatar: require('@/assets/svg/theme/globe-outline.svg'),
      },
      {
        name: 'calendly',
        placeholder: 'Calendly',
        value: this.profile.calendly,
        avatar: require('@/assets/svg/calendly.svg'),
      },
      {
        name: 'zipcode',
        placeholder: 'Zipcode',
        value: this.profile.zipcode,
        avatar: require('@/assets/svg/theme/location-outline.svg'),
      }]);
    },
    socialLinks() {
      return (
        [{
          name: 'instagramUrl',
          placeholder: 'Instagram',
          value: this.profile.instagramUrl,
          avatar: require('@/assets/svg/instagram.svg'),

        }, {
          name: 'facebookUrl',
          placeholder: 'Facebook',
          value: this.profile.facebookUrl,
          avatar: require('@/assets/svg/facebook.svg'),

        }, {
          name: 'slackId',
          placeholder: 'Slack',
          value: this.profile.slackId,
          avatar: require('@/assets/svg/theme/slack-outline.svg'),

        }]);
    },
  },
  data() {
    return ({
      maximumLength: 30,
    });
  },
  methods: {
    getText(id, aObj, type) {
      const obj = _.find(aObj, (x) => x.id === id);
      return _.get(obj, type);
    },
    generateLink(link) {
      try {
        return link ? new URL(link) : '';
      } catch {
        let socialUrl = link;
        const prefix = 'http://';
        if (link.substr(0, prefix.length) !== prefix) {
          socialUrl = prefix + socialUrl;
        }
        return socialUrl;
      }
    },
  },
  validations: {
    profile: {
      number: {
        required,
        minLength: minLength(12),
      },
      email: {
        required,
        email,
      },
      website: {
        required,
        url,
      },
      calendly: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          // eslint-disable-next-line
          return ((!this.profile.isTrainee && this.role === 'producer' ) || (this.profile.isDefaultContact && this.role === 'executive' ));
        }),
        url,
      },
      instagramUrl: {
        url,
      },
      facebookUrl: {
        url,
      },
      slackId: {
      },
      businessTypeIds: {},
      zipcode: {
        minLength: minLength(5),
        // maxLength: maxLength(10),
        // eslint-disable-next-line func-names
        required,
        validZipcode: (value) => /^[0-9A-Z]+$/.test(value),
        numeric,
        maxLength: maxLength(5),
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .input-field {
      height:70px !important;
    }
  .social-item {
      > div {
        max-width: 100%;
      }
      .anchorlink {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.3em;
      }
      .about-contact {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.3em;
      }
    }
</style>

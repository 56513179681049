<template>
  <v-container fluid v-if="agency_customer || customer">
        <CustomerProfileSettings />
  </v-container>
  <v-container fluid v-else-if="role !== 'creative'"
  :fill-height="customer ? true : false"
  class="main-container"
  >
    <v-row class="pb-12 ma-auto"> <PageTitle /> </v-row>
    <v-row align="center" justify="center"
      class="profile-wrapper d-flex align-stretch" v-if="profileCompleted">
      <v-col cols="12" md="4"
        class="px-md-0 align-self-stretch"
      >
        <v-card class="profile-card">
          <v-container pa-0 class="h-100">
            <v-row>
              <v-col class="d-flex align-center pb-0">
                <div class="text-uppercase card-heading">
                  Profile
                </div>
              </v-col>
              <v-col class="d-flex justify-end pb-0">
                <v-btn
                v-if="mode!=='normal'"
                text
                class="px-1"
                color="primaryGray1"
                @click="toggleCancel">
                  <span class="pr-2">Cancel</span>
                </v-btn>
                <v-btn text icon color="info" @click="toggleMode">
                  <img v-if="mode==='normal'" src="@/assets/svg/theme/edit.svg" />
                  <span v-else class="text-purple">Save</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="ma-0 card-content profile-details-card">
              <v-col cols="12" class="pa-0">
                <ProfilePic  :mode="mode" :role="role" ref="profilePic"/>
                <ClientOnboarding
                  v-if="customer"
                  :mode="mode"
                  ref="clientOnboarding"
                />
                <ExecutiveOnboarding v-else-if="role === 'producer'"
                  :mode="mode"
                  ref="producerOnboarding"
                  :role="role"
                />
                <UserProfile v-else
                  :mode="mode"
                  ref="userOnboarding"
                  :role="role"
                >
                </UserProfile>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <template v-if="!customer">
        <v-col
          v-if="!onboardingCompleted"
          cols="12" md="8"
          class="d-flex flex-column justify-center h-100 pa-3 pt-0 pt-md-4 pl-md-2"
        >
          <img src="@/assets/svg/theme/profile-without-subscription.svg" alt="no subscription">
        </v-col>
        <v-col
          v-else
          cols="12" md="8"
          class="d-flex pa-3 pt-0 pt-md-4 pl-md-5 pr-md-2"
        >
          <!-- <ClientProfileCards v-if="role === 'client'" :role="role"/> -->
          <ExecutiveProfileCards v-if="role === 'producer'" :role="role"/>
          <!-- eslint-disable-next-line max-len -->
          <EditorProfileCards v-if="role === 'executive' || role === 'editor' || role === 'community_manager'" :role="role"/>
        </v-col>
      </template>
    </v-row>
    <v-row class="ma-0" v-else>
      <v-col cols="10" offset="1" offset-sm="3" sm="6">
        <v-img
          class="empty-profile-image"
          width="100%"
          src="@/assets/svg/theme/empty-profile.svg"
          alt="empty profile"
        />
        <div class="text text-center context-text mt-4">Nothing to see here!</div>
        <v-btn class="d-flex btn-purple" to="/dashboard">
          Complete {{customer ? 'Business' : 'Creative'}} Profile
        </v-btn>
      </v-col>
    </v-row>
    <template
     v-if="showConfirm">
    <v-dialog
      v-model="showConfirm"
      max-width="600"
    >
      <v-card class="py-4" flat>
        <v-card-title class="black--text">
          Please save current changes before editing another section.
          </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="info"
            @click="showConfirm = false"
            class="py-0"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  <v-container
      fluid
      v-if="emailEdit"
    >
      <Modal
        :modal="emailEdit"
        width="650"
        persistent
      >
          <v-card class="pa-5 confirm-popup" flat>
            <v-row>
              <v-col class="d-flex align-center pb-0" cols="11">
                <div class="modal-header-title"> Confirm email update </div>
              </v-col>
            </v-row>
            <v-row class="pa-0">
              <v-col class="font-family-2">
                Please note that you'll be logged out now.
                We’ll send you a confirmation email with a link to confirm your new email address.
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12 font-family-2" xs="12" sm="8">
                <BaseInput
                  outlined
                  dense
                  label="New Email*"
                  name="email"
                  v-model.trim="profile.email"
                  disabled
                >
                </BaseInput>
              </v-col>
              <v-col cols="12" class="d-flex justify-end py-0">
                  <v-btn text
                    @click="cancelEmailUpdate"
                    class="py-0"
                    color="primaryGray1"
                  >
                    Cancel
                  </v-btn>
                  <v-btn text
                    type="submit"
                    class="ml-2"
                    color="info"
                    @click="sendConfirmationEmail"
                  >
                    Confirm
                  </v-btn>
              </v-col>
            </v-row>
          </v-card>
      </Modal>
     </v-container>
  </v-container>
  <v-container fluid v-else>
    <CreativeProfile :isLoading="isLoading" :profileId="userDetails.id"/>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import ProfilePic from '@/components/profile/ProfilePic';
import ClientOnboarding from '@/components/profile/client/Onboarding';
import ExecutiveOnboarding from '@/components/profile/executive/Onboarding';
// import ClientProfileCards from '@/components/profile/client/ProfileCards';
import CreativeProfile from '@/components/profile/creative/ProfileView';
import EditorProfileCards from '@/components/profile/editor/ProfileCards';
import ExecutiveProfileCards from '@/components/profile/executive/ProfileCards';
import UserProfile from '@/components/profile/UserProfile';
import EventBus from '@/helpers/event-bus';
import Modal from '@/components/common/Modal';
import BaseInput from '@/components/common/BaseInput';
import CustomerProfileSettings from '@/views/Settings';
import PageTitle from '@/components/common/PageTitle';

const namespace = 'profile';

export default {
  name: 'Profile',
  components: {
    ProfilePic,
    ClientOnboarding,
    ExecutiveOnboarding,
    // ClientProfileCards,
    CreativeProfile,
    ExecutiveProfileCards,
    EditorProfileCards,
    UserProfile,
    Modal,
    BaseInput,
    CustomerProfileSettings,
    PageTitle,
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters(namespace, ['userProfile']),
    errors() {
      return () => {
        const errors = [];
        if (!this.$v.email.$dirty) return errors;
        if (!this.$v.email.email) { errors.push('Please provide a valid email address'); }
        if (!this.$v.email.required) { errors.push('Please provide your new email address'); }
        return errors;
      };
    },
    profileCompleted() {
      if (this.role !== 'executive') {
        return _.get(this.userDetails, 'onboardingStatus.profile');
      }
      return true;
    },
    onboardingCompleted() {
      if (this.role !== 'executive') {
        return _.get(this.userDetails, 'onboardingStatus.success');
      }
      return true;
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    customer() {
      return ['client', 'member'].includes(this.role);
    },
    agency_customer() {
      return ['agency_owner', 'agency_member'].includes(this.role);
    },
    profile() {
      if (this.customer) {
        return _.get(this.userProfile, 'client');
      }
      return _.get(this.userProfile, this.role);
    },
    multipleEdits() {
      return this.mode === 'edit' || this.executiveEdit === 'edit';
    },
  },
  data() {
    return ({
      mode: 'normal',
      executiveEdit: 'normal',
      showConfirm: false,
      email: '',
      emailEdit: false,
      actionToCall: '',
      isLoading: false,
    });
  },
  methods: {
    ...mapActions(namespace, ['getProfile']),
    ...mapActions(namespace, ['clientProfileUpdate', 'producerProfileUpdate',
      'editorProfileUpdate', 'userProfileUpdate', 'updateEmail']),
    ...mapActions('user', ['logoutUser']),
    async toggleCancel() {
      this.mode = 'normal';
      this.showConfirm = false;
      EventBus.$emit('profile-edit', this.mode);
      this.getProfile();
    },
    async toggleMode() {
      if (this.mode === 'normal') {
        this.showConfirm = this.multipleEdits;
        if (this.executiveEdit === 'normal' && this.mode === 'normal') {
          this.mode = 'edit';
          EventBus.$emit('profile-edit', this.mode);
          this.showConfirm = false;
        }
      } else {
        this.$refs.profilePic.$v.$touch();
        let profileRef;
        switch (this.role) {
          case 'client':
          case 'member':
            this.actionToCall = 'clientProfileUpdate';
            profileRef = 'clientOnboarding';
            break;
          case 'producer':
            this.actionToCall = 'producerProfileUpdate';
            profileRef = 'producerOnboarding';
            break;
          default:
            this.actionToCall = 'userProfileUpdate';
            profileRef = 'userOnboarding';
        }

        this.$refs[profileRef].$v.$touch();
        if (!this.$refs.profilePic.$v.$invalid && !this.$refs[profileRef].$v.$invalid) {
          // this.customer ? 'clientProfileUpdate' : `${this.role}ProfileUpdate`;
          if (this.email !== this.profile.email) {
            this.emailEdit = true;
          } else {
            const response = await this[this.actionToCall](this.profile);
            if (response.success) {
              this.mode = 'normal';
              EventBus.$emit('profile-edit', this.mode);
            }
          }
        }
      }
    },
    cancelEmailUpdate() {
      this.$v.$reset();
      this.emailEdit = false;
    },
    async sendConfirmationEmail() {
      const payload = Object.assign(this.profile, { emailUpdated: true });
      const response = await this[this.actionToCall](payload);
      if (response.success) {
        await this.logoutUser();
        this.$router.push('/');
      }
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getProfile();
    this.isLoading = false;
    this.email = this.profile.email;
    EventBus.$on('executive-edit', (val) => {
      this.executiveEdit = val;
      this.getProfile();
    });
  },
  beforeDestroy() {
    EventBus.$off('executive-edit');
  },
};
</script>

<style lang="scss" scoped>
  .profile-card {
    padding: 0 20px;
  }
  .card-heading {
      font-weight: bold;
      letter-spacing: -0.28px;
      color: $card-title;
  }
  .context-text {
    font-size: 24px;
    letter-spacing: -0.48px;
  }
  ::v-deep .v-text-field {
    padding-top: 0 !important;
  }
  .empty-profile-image {
    margin-top: 75px;
  }
  .complete-profile-button {
    margin: auto;
    width: 300px;
    margin-top: 20px;
    ::v-deep .v-btn__content {
      font-size: 16px;
    }
  }
  ::v-deep .v-chip__content {
    color: $primary2;
  }
  @media (min-width: 960px) {
    .profile-card {
      height: 100%;
    }
  }
</style>

<style lang="scss">
  .profile-details-card {
  .v-subheader {
    padding: 0;
    height: 22px;
    margin: 5px 0 5px;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: -0.32px;
    color: $neutral1;
  }
  .v-list-item {
    font-family: $fontFamily1;
    letter-spacing: -0.02px;
    color: $neutral1;
    min-height: 28px;
    margin: 0;
  }
  .v-list-item__avatar {
    width: 20px !important;
    height: 20px !important;
    margin: 0;
    min-width: 0 !important;
    border-radius: 0;
    margin-right: 15px !important;
    &.edit {
      margin-bottom: 15px;
    }
  }
  .v-list-item__content {
    padding: 5px 0 0;
  }
  .v-chip {
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .onboarding-details {
    .v-list-item {
      padding: 0;
    }
  }
  .base-input {
    width: 100%;
  }
  .auto-complete {
    width: 100%;
    margin-bottom: 0;
  }
  .social-item {
    > div {
      max-width: 100%;
    }
    .anchorlink {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.3em;
    }
  }
  .zipcode {
    font-size: 16px;
  }
  }
  .profile-padding {
    padding: 16px;
  }
  @media (max-width: 600px) {
    .profile-padding {
      padding: 20px 30px 8px 30px !important;
    }
    .client-padding {
      padding-top: 0px !important;
      padding-left: 35px !important;
    }
  }
</style>

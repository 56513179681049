<template>
  <v-row class="ma-0 onboarding-details">
    <v-col cols="12" class="pa-0">
      <v-list
        subheader
      >
        <v-subheader>
          Contact
        </v-subheader>
        <v-divider class="mb-2"></v-divider>
        <v-list-item
          v-for="(detail, i) in basicDetails"
          :key="`basic${i}`"
          :class="{'hidden-screen-only': (!detail.value && mode === 'normal')}"
        >
          <v-list-item-avatar :class="mode">
            <v-img :src="detail.avatar" contain></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <BaseInput
              v-if="detail.name === 'number' && mode === 'edit'"
              :key="`input${i}`"
              v-mask="'###-###-####'"
              type="tel"
              outlined
              dense
              :mode="mode"
              :disabled="detail.disable"
              :label="detail.placeholder"
              :name="detail.name"
              v-model.trim="profile[detail.name]"
              :status="errors(detail.name).length ? 'error': 'normal'"
              :error-messages="errors(detail.name)"
              @input="$v.profile[detail.name].$touch()"
              @blur="$v.profile[detail.name].$touch()"
            >
              <div class='mb-2'> {{ detail.value }} </div>
            </BaseInput>
            <BaseInput
              v-else
              outlined
              dense
              :mode="mode"
              :disabled="detail.disable"
              :label="detail.placeholder"
              :name="detail.name"
              v-model.trim="profile[detail.name]"
              :status="errors(detail.name).length ? 'error': 'normal'"
              :error-messages="errors(detail.name)"
              @input="$v.profile[detail.name].$touch()"
              @blur="$v.profile[detail.name].$touch()"
            >
              <div class='mb-2'>
                <span> {{ detail.value }} </span>
              </div>
            </BaseInput>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-subheader>
          Social
        </v-subheader>
        <v-divider class="mb-2"></v-divider>
        <v-list-item
          v-for="(link, j) in socialLinks" :key="`link${j}`"
          class="icon-position d-flex align-middle"
        >
          <v-list-item-avatar :class="mode">
            <v-img :src="link.avatar" contain></v-img>
          </v-list-item-avatar>
          <v-list-item-content class="social-item">
            <BaseInput
              outlined
              dense
              :mode="mode"
              :disabled="link.disable"
              :label="link.placeholder"
              :name="link.name"
              v-model.trim="profile[link.name]"
              :status="errors(link.name).length ? 'error': 'normal'"
              :error-messages="errors(link.name)"
              @input="$v.profile[link.name].$touch()"
              @blur="$v.profile[link.name].$touch()"
            >
              <a class="anchorlink mb-2" :href="generateLink(link.value)" target="_blank">
                {{link.value}}
              </a>
            </BaseInput>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable global-require */

import _ from 'lodash';
import { mapGetters } from 'vuex';
import { email, required, minLength } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import { url } from '@/helpers';
import BaseInput from '@/components/common/BaseInput';
// import AutoCompleteField from '@/components/common/AutoCompleteField';

export default {
  name: 'ClientOnboarding',
  components: {
    BaseInput,
    // AutoCompleteField,
  },
  directives: {
    mask,
  },
  props: {
    mode: {
      type: String,
      default: 'normal',
    },
  },
  computed: {
    ...mapGetters('profile', ['businessTypes', 'contentTypes']),
    ...mapGetters('profile', { profile: 'clientProfile' }),
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.profile[field].$dirty) return errors;
        switch (field) {
          case 'number':
            if (!this.$v.profile.number.required) { errors.push('Please provide your Phone Number'); }
            if (!this.$v.profile.number.minLength) { errors.push('Please provide valid Phone Number'); }
            break;
          case 'email':
            if (!this.$v.profile.email.required) { errors.push('Please provide your Email'); }
            if (!this.$v.profile.email.email) { errors.push('Please provide valid Email'); }
            break;
          case 'businessTypeId':
            if (!this.$v.profile.businessTypeId.required) { errors.push('Please select your Business Vertical'); }
            break;
          case 'instagramUrl':
          case 'facebookUrl':
            if (!this.$v.profile[field].url) { errors.push('Please provide valid url'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    basicDetails() {
      return ([{
        name: 'number',
        placeholder: 'Phone Number',
        value: this.profile.number,
        avatar: require('@/assets/svg/theme/phone-outline.svg'),
      }, {
        name: 'email',
        placeholder: 'Email',
        value: this.profile.email,
        avatar: require('@/assets/svg/theme/email-outline.svg'),
        // mode: 'normal',
      }]);
    },
    socialLinks() {
      return ([{
        name: 'instagramUrl',
        placeholder: 'Instagram',
        value: this.profile.instagramUrl,
        avatar: require('@/assets/svg/instagram.svg'),
        icon: 'instagram',
      }, {
        name: 'facebookUrl',
        placeholder: 'Facebook',
        value: this.profile.facebookUrl,
        avatar: require('@/assets/svg/facebook.svg'),
        icon: 'facebook',
      }]);
    },
    businessType() {
      return (id) => _.get(this.businessTypes, `${id}.name`);
    },
  },
  data() {
    return ({
      maximumLength: 30,
      editEmail: false,
    });
  },
  methods: {
    getText(id, aObj, type) {
      const obj = _.find(aObj, (x) => x.id === id);
      return _.get(obj, type);
    },
    generateLink(link) {
      try {
        return link ? new URL(link) : '';
      } catch {
        let socialUrl = link;
        const prefix = 'http://';
        if (link.substr(0, prefix.length) !== prefix) {
          socialUrl = prefix + socialUrl;
        }
        return socialUrl;
      }
    },
  },
  validations: {
    profile: {
      number: {
        required,
        minLength: minLength(12),
      },
      email: {
        required,
        email,
      },
      // website: {
      //   required,
      //   url,
      // },
      // businessTypeId: {
      //   required,
      // },
      instagramUrl: {
        url,
      },
      facebookUrl: {
        url,
      },
    },
  },
};
</script>

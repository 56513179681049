<template>
  <v-container pa-0 class="h-100">
    <v-row class="ma-0">
      <v-col cols="12" class="pa-0 pb-2">
        <v-card class="bio-card">
          <v-container pa-0 pb-2 class="h-100">
            <v-row>
              <v-col class="d-flex align-center pb-0">
                <div class="text-uppercase card-heading">Bio</div>
              </v-col>
              <v-col class="d-flex justify-end pb-0">
                <v-btn
                v-if="mode.bio!=='normal'"
                text color="primaryGray1" class="px-1" @click="toggleCancel">
                  <span class="pr-2">Cancel</span>
                </v-btn>
                <v-btn text icon color="info" @click="toggleMode('bio')"
                  :disabled="!$v.profile.bio.maxLength">
                  <img v-if="mode.bio ==='normal'" src="@/assets/svg/theme/edit.svg" />
                  <span v-else class="save-btn">Save</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="10" class="text note-text pt-0 pb-0">
                Write a little bit about yourself here for others to see.
              </v-col>
            </v-row>
            <v-row class="ma-0 card-content">
                <v-col cols="12" class="pa-0">
                  <BaseTextAreaField
                      filled
                      rows="3"
                      :mode="mode.bio"
                      placeholder="Biography"
                      name="bio"
                      v-model="profile.bio"
                      :status="errors('bio').length ? 'error': 'normal'"
                      :error-messages="errors('bio')"
                      @input="$v.profile.bio.$touch()"
                      @blur="$v.profile.bio.$touch()"
                  >
                      <span class="d-block mt-2 bio">{{ profile.bio }}</span>
                  </BaseTextAreaField>
                </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" class="pa-0 pb-2 pt-2">
        <v-card class="agreements-card">
          <agreements :files="agreements($attrs.role)" />
        </v-card>
      </v-col>
      <v-col cols="12" class="executive-card-image-wrapper pt-2">
        <v-img
          class="executive-card-image"
          src="@/assets/svg/theme/profile-executive.svg"
          alt="empty profile"
          contain
        />
      </v-col>
    </v-row>
    <template
     v-if="showConfirm">
    <v-dialog
      v-model="showConfirm"
      max-width="600"
    >
      <v-card class="py-4" flat>
        <v-card-title class="black--text">
          Please save current changes before editing another section.
          </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="info"
            @click="showConfirm = false"
            class="py-0"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { maxLength } from 'vuelidate/lib/validators';
import BaseTextAreaField from '@/components/common/BaseTextAreaField';
import EventBus from '@/helpers/event-bus';
import Agreements from '../common/Agreements';

export default {
  name: 'ProfileCards',
  components: {
    Agreements,
    BaseTextAreaField,
  },
  computed: {
    ...mapGetters('profile', ['agreements']),
    ...mapGetters('profile', { profile: 'producerProfile' }),
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.profile[field].$dirty) return errors;
        switch (field) {
          case 'bio':
            if (!this.$v.profile.bio.maxLength) { errors.push('Bio length must be less than 340 characters'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    multipleEdits() {
      return this.profileEdit === 'edit' || this.mode.bio === 'edit';
    },
  },
  data() {
    return {
      mode: {
        bio: 'normal',
      },
      showConfirm: false,
      profileEdit: 'normal',
    };
  },
  methods: {
    ...mapActions('profile', ['producerProfileUpdate']),
    getText(id, aObj, type) {
      const obj = _.find(aObj, (x) => x.id === id);
      return _.get(obj, type);
    },
    async toggleCancel() {
      this.mode.bio = 'normal';
      this.profileEdit = 'normal';
      this.showConfirm = false;
      EventBus.$emit('executive-edit', this.mode.bio);
    },
    async toggleMode(type) {
      if (this.mode[type] === 'normal') {
        this.showConfirm = this.multipleEdits;
        if (this.mode.bio === 'normal' && this.profileEdit === 'normal') {
          this.mode[type] = 'edit';
          EventBus.$emit('executive-edit', this.mode.bio);
          this.showConfirm = false;
        }
      } else {
        const response = await this.producerProfileUpdate(this.profile);
        if (response.success) {
          this.mode[type] = 'normal';
          EventBus.$emit('executive-edit', this.mode.bio);
        }
      }
    },
  },
  validations: {
    profile: {
      bio: {
        maxLength: maxLength(340),
      },
    },
  },
  mounted() {
    EventBus.$on('profile-edit', (val) => { this.profileEdit = val; });
  },
  beforeDestroy() {
    EventBus.$off('profile-edit');
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .bio-card {
    padding: 0 25px;
    .input-field {
      height: inherit;
    }
    .bio {
      white-space: pre-wrap;
      max-height: 106px;
      overflow: auto;
      font-family: $fontFamily1;
      font-size: 16px;
      letter-spacing: -0.02px;
    }
  }
    .card-heading {
        font-weight: bold;
        letter-spacing: -0.28px;
        color: $card-title;
    }
    .note-text {
        font-family: $fontFamily1;
        font-size: 12px;
        letter-spacing: -0.12px;
        color: $neutral1;
    }
    .uploaded-document {
      margin-right: 16px;
      .v-card {
        width: 122px;
        height: 122px;
      }
    }
    .agreements-card {
      padding: 0 25px;
    }
    .agreements {
      overflow-x: auto;
    }
    .executive-card-image-wrapper {
      padding: 0 50px;
    }
    ::v-deep .v-textarea textarea{
      margin-top: 5px;
    }
    @media (min-width: 960px) {
      .bio-card {
        min-height: 150px;
      }
      .executive-card-image-wrapper .v-image{
        min-height: 350px;
      }
    }
    .save-btn {
      color: $secondary1;
    }
</style>

<template>
  <v-row class="ma-0 onboarding-details">
    <v-col cols="12" class="pa-0">
      <v-list
        subheader
      >
        <v-subheader>Contact</v-subheader>
        <v-divider class="pb-2"></v-divider>
        <v-list-item
          v-for="(detail, i) in basicDetails"
          :key="`basic${i}`"
          :class="{'hidden-screen-only': (!detail.value && mode === 'normal')}"
          class="d-flex align-middle"
        >
          <v-list-item-avatar
            class="d-flex align-middle"
            :class="mode"
          >
            <v-img :src="detail.avatar" contain></v-img>
          </v-list-item-avatar>
          <BaseInput
            v-if="detail.name === 'number' && mode === 'edit'"
            :key="`input${i}`"
            v-mask="'###-###-####'"
            type="tel"
            dense
            outlined
            :mode="mode"
            :disabled="detail.disable"
            :label="detail.placeholder"
            :name="detail.name"
            v-model.trim="profile[detail.name]"
            :status="errors(detail.name).length ? 'error': 'normal'"
            :error-messages="errors(detail.name)"
            @input="$v.profile[detail.name].$touch()"
            @blur="$v.profile[detail.name].$touch()"
          >
            <div class='my-1'> {{ detail.value }} </div>
          </BaseInput>
          <BaseInput
            v-else
            dense
            outlined
            :mode="mode"
            :disabled="detail.disable"
            :label="detail.placeholder"
            :name="detail.name"
            v-model.trim="profile[detail.name]"
            :status="errors(detail.name).length ? 'error': 'normal'"
            :error-messages="errors(detail.name)"
            @input="$v.profile[detail.name].$touch()"
            @blur="$v.profile[detail.name].$touch()"
          >
            <div class='mb-2'>
              <span> {{ detail.value }} </span>
            </div>
          </BaseInput>
        </v-list-item>
        <v-subheader  :class="{'ma-0': mode === 'edit'}">
          Social
        </v-subheader>
        <v-divider class="pb-2"></v-divider>
        <v-list-item
          v-for="(link, j) in socialLinks" :key="`link${j}`"
          class="icon-position d-flex align-middle"
        >
          <v-list-item-avatar :class="mode">
            <v-img :src="link.avatar" contain></v-img>
          </v-list-item-avatar>
          <v-list-item-content class="social-item">
            <BaseInput
              outlined
              dense
              :mode="mode"
              :disabled="link.disable"
              :label="link.placeholder"
              :name="link.name"
              v-model.trim="profile[link.name]"
              :status="errors(link.name).length ? 'error': 'normal'"
              :error-messages="errors(link.name)"
              @input="$v.profile[link.name].$touch()"
              @blur="$v.profile[link.name].$touch()"
            >
              <a class="anchorlink a-link mb-2" :href="generateLink(link.value)" target="_blank">
                {{link.value}}
              </a>
            </BaseInput>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable global-require */

import _ from 'lodash';
import { mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import {
  email, required, minLength, requiredIf,
}
from 'vuelidate/lib/validators';
import { url } from '@/helpers';
import BaseInput from '@/components/common/BaseInput';

export default {
  name: 'CreativeOnboarding',
  components: {
    BaseInput,
  },
  directives: {
    mask,
  },
  props: {
    mode: {
      type: String,
      default: 'normal',
    },
    role: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('profile', ['editorProfile', 'executiveProfile', 'communityManagerProfile', 'agencyCustomer']),
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.profile[field].$dirty) return errors;
        const calendlyValidation = ((!this.profile.isTrainee && this.role === 'producer') || (this.profile.isDefaultContact && this.role === 'executive'));
        switch (field) {
          case 'number':
            if (!this.$v.profile.number.required) { errors.push('Please provide your Phone Number'); }
            if (!this.$v.profile.number.minLength) { errors.push('Please provide valid Phone Number'); }
            break;
          case 'calendly':
            if (!this.$v.profile.calendly.required && calendlyValidation) { errors.push('Please provide your calendly link'); }
            // eslint-disable-next-line
            if ( this.$v.profile.calendly.$model && this.$v.profile.calendly.$model.indexOf('calendly.com')<0) { errors.push('Please provide valid calendly url'); }
            break;
          case 'email':
            if (!this.$v.profile.email.required) { errors.push('Please provide your Email'); }
            if (!this.$v.profile.email.email) { errors.push('Please provide valid Email'); }
            break;
          case 'instagramUrl':
          case 'facebookUrl':
            if (!this.$v.profile[field].url) { errors.push('Please provide valid url'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    profile() {
      switch (this.role) {
        case 'agency_owner':
        case 'agency_member':
          return this.agencyCustomer;
        case 'executive':
          return this.executiveProfile;
        case 'community_manager':
          return this.communityManagerProfile;
        default:
          return this.editorProfile;
      }
    },
    basicDetails() {
      if (this.role === 'executive' || this.role === 'producer') {
        return ([{
          name: 'number',
          placeholder: 'Phone Number',
          value: this.profile.number,
          avatar: require('@/assets/svg/theme/phone-outline.svg'),
        }, {
          name: 'email',
          placeholder: 'Email',
          value: this.profile.email,
          avatar: require('@/assets/svg/theme/email-outline.svg'),
        },
        {
          name: 'calendly',
          placeholder: 'Calendly',
          value: this.profile.calendly,
          avatar: require('@/assets/svg/calendly.svg'),
        }]);
      }
      return ([{
        name: 'number',
        placeholder: 'Phone Number',
        value: this.profile.number,
        avatar: require('@/assets/svg/theme/phone-outline.svg'),
      }, {
        name: 'email',
        placeholder: 'Email',
        value: this.profile.email,
        avatar: require('@/assets/svg/theme/email-outline.svg'),
      }]);
    },
    socialLinks() {
      return (
        [{
          name: 'instagramUrl',
          placeholder: 'Instagram',
          value: this.profile.instagramUrl,
          avatar: require('@/assets/svg/instagram.svg'),

        }, {
          name: 'facebookUrl',
          placeholder: 'Facebook',
          value: this.profile.facebookUrl,
          avatar: require('@/assets/svg/facebook.svg'),

        }, {
          name: 'slackId',
          placeholder: 'Slack',
          value: this.profile.slackId,
          avatar: require('@/assets/svg/theme/slack-outline.svg'),
        }]);
    },
  },
  data() {
    return ({
      maximumLength: 30,
    });
  },
  methods: {
    getText(id, aObj, type) {
      const obj = _.find(aObj, (x) => x.id === id);
      return _.get(obj, type);
    },
    generateLink(link) {
      try {
        return link ? new URL(link) : '';
      } catch {
        let socialUrl = link;
        const prefix = 'http://';
        if (link.substr(0, prefix.length) !== prefix) {
          socialUrl = prefix + socialUrl;
        }
        return socialUrl;
      }
    },
  },
  validations: {
    profile: {
      number: {
        required,
        minLength: minLength(12),
      },
      email: {
        required,
        email,
      },
      instagramUrl: {
        url,
      },
      facebookUrl: {
        url,
      },
      slackId: {
      },
      calendly: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          // eslint-disable-next-line
          return ((!this.profile.isTrainee && this.role === 'producer' ) || (this.profile.isDefaultContact && this.role === 'executive' ));
        }),
        url,
      },
    },
  },
};
</script>
